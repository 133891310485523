





























































































































































































































































.profile-ed{
  &-mn {
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    &-image {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  &-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title{
      margin-right: 15px;
    }
  }
}
